import React, { Suspense, lazy, useRef } from "react";

import { CContainer, CFade } from "@coreui/react";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const TheSidebar = lazy(() =>
  import("../../TempCoreUI/containers_public/TheSidebar")
);
const TheHeader = lazy(() =>
  import("../../TempCoreUI/containers_public/TheHeader")
);
const TheFooter = lazy(() =>
  import("../../TempCoreUI/containers_public/TheFooter")
);

const loadingWaiting = (
  <div className="spinner-grow text-dark text-center" role="status">
    <span className="visually-hidden">...</span>
  </div>
);

const FrontendLayout_Skill = (props) => {
  const playerRef = useRef(null);
  // useEffect(() => {
  //   const handleContextMenu = (event) => {
  //     event.preventDefault();
  //   };

  //   // Attach the context menu event listener to the player container
  //   const playerContainer = playerRef.current;
  //   playerContainer.addEventListener("contextmenu", handleContextMenu);

  //   return () => {
  //     // Remove the context menu event listener when the component unmounts
  //     playerContainer.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);

  return (
    <div className="c-app c-default-layout" ref={playerRef}>
      <Suspense fallback={loadingWaiting}>
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader {...props} />
          <div className="c-body">
            <main className="c-main">
              <CContainer fluid>
                <CFade>{props.children}</CFade>
              </CContainer>
            </main>
          </div>
          <TheFooter />
        </div>
      </Suspense>
    </div>
  );
};

export default FrontendLayout_Skill;
