export const URL_SITE = process.env.REACT_APP_API_URL || "http://127.0.0.1:8000/api/";
export const URL_SITE_API_URL = process.env.REACT_APP_SITE_API_URL
export const AWS_S3_EXIST = process.env.REACT_APP_AWS_S3_EXIST
export const AWS_S3_FILE_PUBLIC_URL = process.env.REACT_APP_AWS_S3_FILE_PUBLIC_URL

// BLOG
export const API_BLOG = URL_SITE + "blog/"
export const API_BLOG_CATEGORY_LEVEL_1_GET_ALL = API_BLOG + "blog_category_level_1_get_all_api/";
export const API_BLOG_CATEGORY_LEVEL_1_ADD = API_BLOG + "blog_category_level_1_add_api/";
export const API_BLOG_CATEGORY_LEVEL_1_DELETE = API_BLOG + "blog_category_level_1_delete_api/";
export const API_BLOG_CATEGORY_LEVEL_1_GET_BY_ID = API_BLOG + "blog_category_level_1_get_by_id_api/";
export const API_BLOG_CATEGORY_LEVEL_1_EDIT = API_BLOG + "blog_category_level_1_edit_api/";
export const API_BLOG_CATEGORY_LEVEL_1_CHANGE_STATUS_PUBLISH = API_BLOG + "blog_category_level_1_change_status_publish_api/";

export const API_BLOG_CATEGORY_LEVEL_2_ADD = API_BLOG + "blog_category_level_2_add_api/";
export const API_BLOG_CATEGORY_LEVEL_2_DELETE = API_BLOG + "blog_category_level_2_delete_api/";
export const API_BLOG_CATEGORY_LEVEL_2_GET_BY_ID = API_BLOG + "blog_category_level_2_get_by_id_api/";
export const API_BLOG_CATEGORY_LEVEL_2_EDIT = API_BLOG + "blog_category_level_2_edit_api/";
export const API_BLOG_CATEGORY_LEVEL_2_GET_ALL_BY_ID_CATEGORY_LEVEL_1 = API_BLOG + "blog_category_level_2_get_all_by_id_category_level_1_api/";
export const API_BLOG_CATEGORY_LEVEL_2_GET_ALL_WITH_POST = API_BLOG + "blog_category_level_2_get_all_with_post_api/";
export const API_BLOG_CATEGORY_LEVEL_2_CHANGE_STATUS_PUBLISH = API_BLOG + "blog_category_level_2_change_status_publish_api/";
export const API_BLOG_CATEGORY_LEVEL_2_GET_ALL_BY_EXCEPT_ID_CATEGORY_LEVEL_2_ANY = API_BLOG + "blog_category_level_2_get_all_by_except_id_category_level_2_any_api/";

export const API_BLOG_POST_GET_ALL = API_BLOG + "blog_post_get_all_api/";
export const API_BLOG_POST_CHANGE_STATUS_PUBLISH = API_BLOG + "blog_post_change_status_publish_api/";
export const API_BLOG_POST_CHANGE_STATUS_PIN_TOP = API_BLOG + "blog_post_change_status_pin_top_api/";
export const API_BLOG_POST_ADD = API_BLOG + "blog_post_add_api/";
export const API_BLOG_POST_GET_BY_ID = API_BLOG + "blog_post_get_by_id_api/";
export const API_BLOG_POST_EDIT = API_BLOG + "blog_post_edit_api/";
export const API_BLOG_POST_GET_BY_CATEGORY_LEVEL_1 = API_BLOG + "blog_post_get_by_category_level_1_api/";
export const API_BLOG_POST_GET_BY_CATEGORY_LEVEL_2 = API_BLOG + "blog_post_get_by_category_level_2_api/";
export const API_BLOG_POST_GET_VIEW_MOST = API_BLOG + "blog_post_get_view_most_api/";
export const API_BLOG_POST_GET_ALL_NOT_PAGINATION = API_BLOG + "blog_post_get_all_not_pagination_api/";
export const API_BLOG_POST_DELETE = API_BLOG + "blog_post_delete_api/";

export const API_BLOG_POST_COMMENT_GET_ALL = API_BLOG + "blog_post_comment_get_all_api/";
export const API_BLOG_POST_COMMENT_GET_ALL_NOT_PUBLISH = API_BLOG + "blog_post_comment_get_all_not_publish_api/";
export const API_BLOG_POST_COMMENT_GET_ALL_PUBLISHED = API_BLOG + "blog_post_comment_get_all_published_api/";
export const API_BLOG_POST_COMMENT_ADD = API_BLOG + "blog_post_comment_add_api/";
export const API_BLOG_POST_COMMENT_DELETE = API_BLOG + "blog_post_comment_delete_api/";
export const API_BLOG_POST_COMMENT_GET_BY_ID = API_BLOG + "blog_post_comment_get_by_id_api/";
export const API_BLOG_POST_COMMENT_EDIT = API_BLOG + "blog_post_comment_edit_api/";
export const API_BLOG_POST_COMMENT_CHANGE_STATUS_PUBLISH = API_BLOG + "blog_post_comment_change_status_publish_api/";

export const API_BLOG_POST_COMMENT_REPLYING_GET_ALL = API_BLOG + "blog_post_comment_replying_get_all_api/";
export const API_BLOG_POST_COMMENT_REPLYING_GET_ALL_NOT_PUBLISH = API_BLOG + "blog_post_comment_replying_get_all_not_publish_api/";
export const API_BLOG_POST_COMMENT_REPLYING_GET_ALL_PUBLISHED = API_BLOG + "blog_post_comment_replying_get_all_published_api/";
export const API_BLOG_POST_COMMENT_REPLYING_ADD = API_BLOG + "blog_post_comment_replying_add_api/";
export const API_BLOG_POST_COMMENT_REPLYING_DELETE = API_BLOG + "blog_post_comment_replying_delete_api/";
export const API_BLOG_POST_COMMENT_REPLYING_GET_BY_ID = API_BLOG + "blog_post_comment_replying_get_by_id_api/";
export const API_BLOG_POST_COMMENT_REPLYING_EDIT = API_BLOG + "blog_post_comment_replying_edit_api/";
export const API_BLOG_POST_COMMENT_REPLYING_CHANGE_STATUS_PUBLISH = API_BLOG + "blog_post_comment_replying_change_status_publish_api/";


// export const API_BLOG_POSTS_RECENT_GET = URL_SITE + "blogs/blog_posts_recent_api/";
// export const API_BLOG_CATEGORY_LEVEL_1_GET = URL_SITE + "blogs/blog_get_all_category_level_1_api/";
// export const API_BLOG_POST_DETAIL_BY_SLUG_GET = URL_SITE + "blogs/blog_post_detail_by_slug_api/";
// export const API_BLOG_POSTS_CATEGORY_BY_SLUG_GET = URL_SITE + "blogs/blog_posts_by_category_by_slug_api/";
// export const API_BLOG_POST_ALL_COMMENT_NOT_REVIEWED_GET = URL_SITE + "blogs/post_get_all_comments_api/0/";
// export const API_BLOG_POST_ALL_COMMENT_REVIEWED_GET = URL_SITE + "blogs/post_get_all_comments_api/1/";
// export const API_BLOG_POST_ALL_COMMENT_REVIEWED_AND_OWNER_GET = URL_SITE + "blogs/post_get_all_comments_reviewed_and_owner_api/";
// export const API_BLOG_POST_COMMENT_POST = URL_SITE + "blogs/post_add_commnet_api/";
// export const API_BLOG_POST_COMMENT_DELETE = URL_SITE + "blogs/post_delete_commnet_api/";
// export const API_BLOG_POST_SEARCH_GET = URL_SITE + "blogs/blog_posts_search_api/?search=";

// NEWS
export const API_NEWS = URL_SITE + "news/"
export const API_NEWS_CATEGORY_LEVEL_1_GET_ALL = API_NEWS + "news_category_level_1_get_all_api/";
export const API_NEWS_CATEGORY_LEVEL_1_ADD = API_NEWS + "news_category_level_1_add_api/";
export const API_NEWS_CATEGORY_LEVEL_1_DELETE = API_NEWS + "news_category_level_1_delete_api/";
export const API_NEWS_CATEGORY_LEVEL_1_GET_BY_ID = API_NEWS + "news_category_level_1_get_by_id_api/";
export const API_NEWS_CATEGORY_LEVEL_1_EDIT = API_NEWS + "news_category_level_1_edit_api/";

export const API_NEWS_CATEGORY_LEVEL_2_ADD = API_NEWS + "news_category_level_2_add_api/";
export const API_NEWS_CATEGORY_LEVEL_2_DELETE = API_NEWS + "news_category_level_2_delete_api/";
export const API_NEWS_CATEGORY_LEVEL_2_GET_BY_ID = API_NEWS + "news_category_level_2_get_by_id_api/";
export const API_NEWS_CATEGORY_LEVEL_2_EDIT = API_NEWS + "news_category_level_2_edit_api/";
export const API_NEWS_CATEGORY_LEVEL_2_GET_ALL_BY_ID_CATEGORY_LEVEL_1 = API_NEWS + "news_category_level_2_get_all_by_id_category_level_1_api/";

export const API_NEWS_POST_GET_ALL = API_NEWS + "news_post_get_all_api/";
export const API_NEWS_POST_CHANGE_STATUS_PUBLISH = API_NEWS + "news_post_change_status_publish_api/";
export const API_NEWS_POST_CHANGE_STATUS_PIN_TOP = API_NEWS + "news_post_change_status_pin_top_api/";
export const API_NEWS_POST_ADD = API_NEWS + "news_post_add_api/";
export const API_NEWS_POST_GET_BY_ID = API_NEWS + "news_post_get_by_id_api/";
export const API_NEWS_POST_EDIT = API_NEWS + "news_post_edit_api/";
export const API_NEWS_POST_GET_BY_CATEGORY_LEVEL_1 = API_NEWS + "news_post_get_by_category_level_1_api/";
export const API_NEWS_POST_GET_BY_CATEGORY_LEVEL_2 = API_NEWS + "news_post_get_by_category_level_2_api/";
export const API_NEWS_POST_GET_VIEW_MOST = API_NEWS + "news_post_get_view_most_api/";
export const API_NEWS_POST_GET_ALL_NOT_PAGINATION = API_NEWS + "news_post_get_all_not_pagination_api/";
export const API_NEWS_POST_DELETE = API_NEWS + "news_post_delete_api/";

// SHOP
export const API_SHOP = URL_SITE + "shop/"
export const API_SHOP_CATEGORY_LEVEL_1_GET_ALL = API_SHOP + "shop_category_level_1_get_all_api/";
export const API_SHOP_CATEGORY_LEVEL_1_ADD = API_SHOP + "shop_category_level_1_add_api/";
export const API_SHOP_CATEGORY_LEVEL_1_DELETE = API_SHOP + "shop_category_level_1_delete_api/";
export const API_SHOP_CATEGORY_LEVEL_1_GET_BY_ID = API_SHOP + "shop_category_level_1_get_by_id_api/";
export const API_SHOP_CATEGORY_LEVEL_1_EDIT = API_SHOP + "shop_category_level_1_edit_api/";
export const API_SHOP_CATEGORY_LEVEL_1_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_category_level_1_change_status_publish_api/";

export const API_SHOP_TYPE_CATEGORY_LEVEL_2_GET_ALL = API_SHOP + "shop_type_category_level_2_get_all_api/";
export const API_SHOP_TYPE_CATEGORY_LEVEL_2_ADD = API_SHOP + "shop_type_category_level_2_add_api/";
export const API_SHOP_TYPE_CATEGORY_LEVEL_2_DELETE = API_SHOP + "shop_type_category_level_2_delete_api/";
export const API_SHOP_TYPE_CATEGORY_LEVEL_2_GET_BY_ID = API_SHOP + "shop_type_category_level_2_get_by_id_api/";
export const API_SHOP_TYPE_CATEGORY_LEVEL_2_EDIT = API_SHOP + "shop_type_category_level_2_edit_api/";

export const API_SHOP_CATEGORY_LEVEL_2_ADD = API_SHOP + "shop_category_level_2_add_api/";
export const API_SHOP_CATEGORY_LEVEL_2_DELETE = API_SHOP + "shop_category_level_2_delete_api/";
export const API_SHOP_CATEGORY_LEVEL_2_GET_BY_ID = API_SHOP + "shop_category_level_2_get_by_id_api/";
export const API_SHOP_CATEGORY_LEVEL_2_EDIT = API_SHOP + "shop_category_level_2_edit_api/";
export const API_SHOP_CATEGORY_LEVEL_2_GET_ALL_BY_ID_CATEGORY_LEVEL_1 = API_SHOP + "shop_category_level_2_get_all_by_id_category_level_1_api/";
export const API_SHOP_CATEGORY_LEVEL_2_GET_ALL_WITH_PRODUCT = API_SHOP + "shop_category_level_2_get_all_with_product_api/";
export const API_SHOP_CATEGORY_LEVEL_2_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_category_level_2_change_status_publish_api/";

export const API_SHOP_PRODUCT_GET_ALL = API_SHOP + "shop_product_get_all_api/";
export const API_SHOP_PRODUCT_GET_ALL_DELETED = API_SHOP + "shop_product_get_all_deleted_api/";
export const API_SHOP_PRODUCT_ADD = API_SHOP + "shop_product_add_api/";
export const API_SHOP_PRODUCT_DELETE = API_SHOP + "shop_product_delete_api/";
export const API_SHOP_PRODUCT_DELETE_FOREVER = API_SHOP + "shop_product_delete_forever_api/";
export const API_SHOP_PRODUCT_GET_BY_ID = API_SHOP + "shop_product_get_by_id_api/";
export const API_SHOP_PRODUCT_EDIT = API_SHOP + "shop_product_edit_api/";
export const API_SHOP_PRODUCT_EDIT_USD = API_SHOP + "shop_product_edit_usd_api/";
export const API_SHOP_PRODUCT_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_product_change_status_publish_api/";
export const API_SHOP_PRODUCT_CHANGE_STATUS_IS_AVAILABLE = API_SHOP + "shop_product_change_status_is_available_api/";
export const API_SHOP_PRODUCT_CHANGE_STATUS_IS_FREE_SHIP = API_SHOP + "shop_product_change_status_is_free_ship_api/";
export const API_SHOP_PRODUCT_CHANGE_STATUS_IS_NEW = API_SHOP + "shop_product_change_status_is_new_api/";
export const API_SHOP_PRODUCT_CHANGE_STATUS_IS_SELLEST = API_SHOP + "shop_product_change_status_is_sellest_api/";
export const API_SHOP_PRODUCT_CHANGE_STATUS_IS_HOT = API_SHOP + "shop_product_change_status_is_hot_api/";
export const API_SHOP_PRODUCT_CHANGE_STATUS_IS_POPULAR = API_SHOP + "shop_product_change_status_is_popular_api/";
export const API_SHOP_PRODUCT_CHANGE_STATUS_IS_TREND = API_SHOP + "shop_product_change_status_is_trend_api/";
export const API_SHOP_PRODUCT_CHANGE_STATUS_STORE = API_SHOP + "shop_product_change_status_store_api/";
export const API_SHOP_PRODUCT_CHANGE_STATUS_IS_AFFILIATE = API_SHOP + "shop_product_change_status_is_affiliate_api/";
export const API_SHOP_PRODUCT_GET_ALL_NOT_PAGINATION = API_SHOP + "shop_product_get_all_not_pagination_api/";
export const API_SHOP_PRODUCT_GET_VIDEO_POPULAR_MOST = API_SHOP + "shop_product_get_video_view_popular_most_api/";
export const API_SHOP_PRODUCT_GET_IMAGE_POPULAR_MOST = API_SHOP + "shop_product_get_image_view_popular_most_api/";

export const API_SHOP_PRODUCT_IMAGE_GET_ALL = API_SHOP + "shop_product_image_get_all_api/";
export const API_SHOP_PRODUCT_IMAGE_GET_ALL_BY_PRODUCT_ID = API_SHOP + "shop_product_image_get_all_by_product_id_api/";
export const API_SHOP_PRODUCT_IMAGE_ADD = API_SHOP + "shop_product_image_add_api/";
export const API_SHOP_PRODUCT_IMAGE_DELETE = API_SHOP + "shop_product_image_delete_api/";
export const API_SHOP_PRODUCT_IMAGE_GET_BY_ID = API_SHOP + "shop_product_image_get_by_id_api/";
export const API_SHOP_PRODUCT_IMAGE_EDIT = API_SHOP + "shop_product_image_edit_api/";

export const API_SHOP_PRODUCT_BUY_GROUP_GET_ALL = API_SHOP + "shop_product_buy_group_get_all_api/";
export const API_SHOP_PRODUCT_BUY_GROUP_GET_ALL_BY_PRODUCT_ID = API_SHOP + "shop_product_buy_group_get_all_by_product_id_api/";
export const API_SHOP_PRODUCT_BUY_GROUP_ADD = API_SHOP + "shop_product_buy_group_add_api/";
export const API_SHOP_PRODUCT_BUY_GROUP_DELETE = API_SHOP + "shop_product_buy_group_delete_api/";
export const API_SHOP_PRODUCT_BUY_GROUP_GET_BY_ID = API_SHOP + "shop_product_buy_group_get_by_id_api/";
export const API_SHOP_PRODUCT_BUY_GROUP_EDIT = API_SHOP + "shop_product_buy_group_edit_api/";

export const API_SHOP_ORDER_GET_ALL_NEW = API_SHOP + "shop_order_get_all_new_api/";
export const API_SHOP_ORDER_GET_ALL_SHIPPING = API_SHOP + "shop_order_get_all_shipping_api/";
export const API_SHOP_ORDER_GET_ALL_FINISHED = API_SHOP + "shop_order_get_all_finished_api/";
export const API_SHOP_ORDER_ADD = API_SHOP + "shop_order_add_api/";
export const API_SHOP_ORDER_DELETE = API_SHOP + "shop_order_delete_api/";
export const API_SHOP_ORDER_GET_BY_ID = API_SHOP + "shop_order_get_by_id_api/";
export const API_SHOP_ORDER_EDIT = API_SHOP + "shop_order_edit_api/";
export const API_SHOP_ORDER_CHANGE_STATUS_SHIPPING = API_SHOP + "shop_order_change_status_shipping_api/";
export const API_SHOP_ORDER_CHANGE_STATUS_FINISHED = API_SHOP + "shop_order_change_status_finished_api/";
export const API_SHOP_ORDER_CHANGE_COMMISSION_PERCENT = API_SHOP + "shop_order_change_commission_percent_api/";

export const API_SHOP_ORDER_PRODUCT_GET_ALL = API_SHOP + "shop_order_product_get_all_api/";
export const API_SHOP_ORDER_PRODUCT_GET_ALL_BY_ORDER_ID = API_SHOP + "shop_order_product_get_all_by_order_id_api/";
export const API_SHOP_ORDER_PRODUCT_ADD = API_SHOP + "shop_order_product_add_api/";
export const API_SHOP_ORDER_PRODUCT_DELETE = API_SHOP + "shop_order_product_delete_api/";
export const API_SHOP_ORDER_PRODUCT_GET_BY_ID = API_SHOP + "shop_order_product_get_by_id_api/";
export const API_SHOP_ORDER_PRODUCT_EDIT = API_SHOP + "shop_order_product_edit_api/";

export const API_SHOP_PRODUCT_COMMENT_GET_ALL = API_SHOP + "shop_product_comment_get_all_api/";
export const API_SHOP_PRODUCT_COMMENT_GET_ALL_NOT_PUBLISH = API_SHOP + "shop_product_comment_get_all_not_publish_api/";
export const API_SHOP_PRODUCT_COMMENT_GET_ALL_PUBLISHED = API_SHOP + "shop_product_comment_get_all_published_api/";
export const API_SHOP_PRODUCT_COMMENT_ADD = API_SHOP + "shop_product_comment_add_api/";
export const API_SHOP_PRODUCT_COMMENT_DELETE = API_SHOP + "shop_product_comment_delete_api/";
export const API_SHOP_PRODUCT_COMMENT_GET_BY_ID = API_SHOP + "shop_product_comment_get_by_id_api/";
export const API_SHOP_PRODUCT_COMMENT_EDIT = API_SHOP + "shop_product_comment_edit_api/";
export const API_SHOP_PRODUCT_COMMENT_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_product_comment_change_status_publish_api/";

export const API_SHOP_PRODUCT_COMMENT_REPLYING_GET_ALL = API_SHOP + "shop_product_comment_replying_get_all_api/";
export const API_SHOP_PRODUCT_COMMENT_REPLYING_GET_ALL_NOT_PUBLISH = API_SHOP + "shop_product_comment_replying_get_all_not_publish_api/";
export const API_SHOP_PRODUCT_COMMENT_REPLYING_GET_ALL_PUBLISHED = API_SHOP + "shop_product_comment_replying_get_all_published_api/";
export const API_SHOP_PRODUCT_COMMENT_REPLYING_ADD = API_SHOP + "shop_product_comment_replying_add_api/";
export const API_SHOP_PRODUCT_COMMENT_REPLYING_DELETE = API_SHOP + "shop_product_comment_replying_delete_api/";
export const API_SHOP_PRODUCT_COMMENT_REPLYING_GET_BY_ID = API_SHOP + "shop_product_comment_replying_get_by_id_api/";
export const API_SHOP_PRODUCT_COMMENT_REPLYING_EDIT = API_SHOP + "shop_product_comment_replying_edit_api/";
export const API_SHOP_PRODUCT_COMMENT_REPLYING_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_product_comment_replying_change_status_publish_api/";

// =========================================================================================


export const API_SHOP_SALE_STAFF_GET_ALL = API_SHOP + "shop_sale_staff_get_all_api/";
export const API_SHOP_SALE_STAFF_ADD = API_SHOP + "shop_sale_staff_add_api/";
export const API_SHOP_SALE_STAFF_DELETE = API_SHOP + "shop_sale_staff_delete_api/";
export const API_SHOP_SALE_STAFF_GET_BY_ID = API_SHOP + "shop_sale_staff_get_by_id_api/";
export const API_SHOP_SALE_STAFF_EDIT = API_SHOP + "shop_sale_staff_edit_api/";
export const API_SHOP_SALE_STAFF_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_sale_staff_change_status_publish_api/";

export const API_SHOP_OUR_CUSTOMER_GET_ALL = API_SHOP + "shop_our_customer_get_all_api/";
export const API_SHOP_OUR_CUSTOMER_ADD = API_SHOP + "shop_our_customer_add_api/";
export const API_SHOP_OUR_CUSTOMER_DELETE = API_SHOP + "shop_our_customer_delete_api/";
export const API_SHOP_OUR_CUSTOMER_GET_BY_ID = API_SHOP + "shop_our_customer_get_by_id_api/";
export const API_SHOP_OUR_CUSTOMER_EDIT = API_SHOP + "shop_our_customer_edit_api/";
export const API_SHOP_OUR_CUSTOMER_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_our_customer_change_status_publish_api/";

export const API_SHOP_INTRO_SLIDE_GET_ALL = API_SHOP + "shop_intro_slide_get_all_api/";
export const API_SHOP_INTRO_SLIDE_ADD = API_SHOP + "shop_intro_slide_add_api/";
export const API_SHOP_INTRO_SLIDE_DELETE = API_SHOP + "shop_intro_slide_delete_api/";
export const API_SHOP_INTRO_SLIDE_GET_BY_ID = API_SHOP + "shop_intro_slide_get_by_id_api/";
export const API_SHOP_INTRO_SLIDE_EDIT = API_SHOP + "shop_intro_slide_edit_api/";
export const API_SHOP_INTRO_SLIDE_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_intro_slide_change_status_publish_api/";

export const API_SHOP_CERTIFICATE_GET_ALL = API_SHOP + "shop_certificate_get_all_api/";
export const API_SHOP_CERTIFICATE_ADD = API_SHOP + "shop_certificate_add_api/";
export const API_SHOP_CERTIFICATE_DELETE = API_SHOP + "shop_certificate_delete_api/";
export const API_SHOP_CERTIFICATE_GET_BY_ID = API_SHOP + "shop_certificate_get_by_id_api/";
export const API_SHOP_CERTIFICATE_EDIT = API_SHOP + "shop_certificate_edit_api/";
export const API_SHOP_CERTIFICATE_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_certificate_change_status_publish_api/";

export const API_SHOP_CONTACT_GET_NOT_READ_ALL = API_SHOP + "shop_contact_get_all_not_read_api/";
export const API_SHOP_CONTACT_GET_READ_ALL = API_SHOP + "shop_contact_get_all_read_api/";
export const API_SHOP_CONTACT_ADD = API_SHOP + "shop_contact_add_api/";
export const API_SHOP_CONTACT_DELETE = API_SHOP + "shop_contact_delete_api/";
export const API_SHOP_CONTACT_GET_BY_ID = API_SHOP + "shop_contact_get_by_id_api/";
export const API_SHOP_CONTACT_EDIT = API_SHOP + "shop_contact_edit_api/";
export const API_SHOP_CONTACT_EDIT_FULL_NAME = API_SHOP + "shop_contact_edit_full_name_api/";
export const API_SHOP_CONTACT_CHANGE_STATUS_READ = API_SHOP + "shop_contact_change_status_read_api/";
export const API_SHOP_CONTACT_CHANGE_STATUS_MALE = API_SHOP + "shop_contact_change_status_male_api/";
export const API_SHOP_CONTACT_CHANGE_STATUS_SUBSCRIBE_PROMOTION = API_SHOP + "shop_contact_change_status_subscribe_promotion_api/";

export const API_SHOP_SUPPORT_INFO_GET_ALL = API_SHOP + "shop_support_info_get_all_api/";
export const API_SHOP_SUPPORT_INFO_ADD = API_SHOP + "shop_support_info_add_api/";
export const API_SHOP_SUPPORT_INFO_DELETE = API_SHOP + "shop_support_info_delete_api/";
export const API_SHOP_SUPPORT_INFO_GET_BY_ID = API_SHOP + "shop_support_info_get_by_id_api/";
export const API_SHOP_SUPPORT_INFO_EDIT = API_SHOP + "shop_support_info_edit_api/";
export const API_SHOP_SUPPORT_INFO_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_support_info_change_status_publish_api/";

export const API_SHOP_HOTLINE_INFO_GET_ALL = API_SHOP + "shop_hotline_info_get_all_api/";
export const API_SHOP_HOTLINE_INFO_ADD = API_SHOP + "shop_hotline_info_add_api/";
export const API_SHOP_HOTLINE_INFO_DELETE = API_SHOP + "shop_hotline_info_delete_api/";
export const API_SHOP_HOTLINE_INFO_GET_BY_ID = API_SHOP + "shop_hotline_info_get_by_id_api/";
export const API_SHOP_HOTLINE_INFO_EDIT = API_SHOP + "shop_hotline_info_edit_api/";
export const API_SHOP_HOTLINE_INFO_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_hotline_info_change_status_publish_api/";

export const API_SHOP_SOCIAL_INFO_GET_ALL = API_SHOP + "shop_social_info_get_all_api/";
export const API_SHOP_SOCIAL_INFO_ADD = API_SHOP + "shop_social_info_add_api/";
export const API_SHOP_SOCIAL_INFO_DELETE = API_SHOP + "shop_social_info_delete_api/";
export const API_SHOP_SOCIAL_INFO_GET_BY_ID = API_SHOP + "shop_social_info_get_by_id_api/";
export const API_SHOP_SOCIAL_INFO_EDIT = API_SHOP + "shop_social_info_edit_api/";
export const API_SHOP_SOCIAL_INFO_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_social_info_change_status_publish_api/";

export const API_SHOP_PAYMENT_INFO_GET_ALL = API_SHOP + "shop_payment_info_get_all_api/";
export const API_SHOP_PAYMENT_INFO_ADD = API_SHOP + "shop_payment_info_add_api/";
export const API_SHOP_PAYMENT_INFO_DELETE = API_SHOP + "shop_payment_info_delete_api/";
export const API_SHOP_PAYMENT_INFO_GET_BY_ID = API_SHOP + "shop_payment_info_get_by_id_api/";
export const API_SHOP_PAYMENT_INFO_EDIT = API_SHOP + "shop_payment_info_edit_api/";
export const API_SHOP_PAYMENT_INFO_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_payment_info_change_status_publish_api/";

export const API_SHOP_BANK_PAYMENT_INFO_GET_ALL = API_SHOP + "shop_bank_payment_info_get_all_api/";
export const API_SHOP_BANK_PAYMENT_INFO_ADD = API_SHOP + "shop_bank_payment_info_add_api/";
export const API_SHOP_BANK_PAYMENT_INFO_DELETE = API_SHOP + "shop_bank_payment_info_delete_api/";
export const API_SHOP_BANK_PAYMENT_INFO_GET_BY_ID = API_SHOP + "shop_bank_payment_info_get_by_id_api/";
export const API_SHOP_BANK_PAYMENT_INFO_EDIT = API_SHOP + "shop_bank_payment_info_edit_api/";
export const API_SHOP_BANK_PAYMENT_INFO_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_bank_payment_info_change_status_publish_api/";

export const API_SHOP_FOOTER1_LINK_INFO_GET_ALL = API_SHOP + "shop_footer1_link_info_get_all_api/";
export const API_SHOP_FOOTER1_LINK_INFO_ADD = API_SHOP + "shop_footer1_link_info_add_api/";
export const API_SHOP_FOOTER1_LINK_INFO_DELETE = API_SHOP + "shop_footer1_link_info_delete_api/";
export const API_SHOP_FOOTER1_LINK_INFO_GET_BY_ID = API_SHOP + "shop_footer1_link_info_get_by_id_api/";
export const API_SHOP_FOOTER1_LINK_INFO_EDIT = API_SHOP + "shop_footer1_link_info_edit_api/";
export const API_SHOP_FOOTER1_LINK_INFO_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_footer1_link_info_change_status_publish_api/";

export const API_SHOP_FOOTER2_LINK_INFO_GET_ALL = API_SHOP + "shop_footer2_link_info_get_all_api/";
export const API_SHOP_FOOTER2_LINK_INFO_ADD = API_SHOP + "shop_footer2_link_info_add_api/";
export const API_SHOP_FOOTER2_LINK_INFO_DELETE = API_SHOP + "shop_footer2_link_info_delete_api/";
export const API_SHOP_FOOTER2_LINK_INFO_GET_BY_ID = API_SHOP + "shop_footer2_link_info_get_by_id_api/";
export const API_SHOP_FOOTER2_LINK_INFO_EDIT = API_SHOP + "shop_footer2_link_info_edit_api/";
export const API_SHOP_FOOTER2_LINK_INFO_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_footer2_link_info_change_status_publish_api/";

export const API_SHOP_STATIC_PAGE_GET_ALL = API_SHOP + "shop_static_page_get_all_api/";
export const API_SHOP_STATIC_PAGE_ADD = API_SHOP + "shop_static_page_add_api/";
export const API_SHOP_STATIC_PAGE_DELETE = API_SHOP + "shop_static_page_delete_api/";
export const API_SHOP_STATIC_PAGE_GET_BY_ID = API_SHOP + "shop_static_page_get_by_id_api/";
export const API_SHOP_STATIC_PAGE_EDIT = API_SHOP + "shop_static_page_edit_api/";
export const API_SHOP_STATIC_PAGE_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_static_page_change_status_publish_api/";

export const API_SHOP_CARE_SERVICE_GET_ALL = API_SHOP + "shop_care_service_get_all_api/";
export const API_SHOP_CARE_SERVICE_ADD = API_SHOP + "shop_care_service_add_api/";
export const API_SHOP_CARE_SERVICE_DELETE = API_SHOP + "shop_care_service_delete_api/";
export const API_SHOP_CARE_SERVICE_GET_BY_ID = API_SHOP + "shop_care_service_get_by_id_api/";
export const API_SHOP_CARE_SERVICE_EDIT = API_SHOP + "shop_care_service_edit_api/";
export const API_SHOP_CARE_SERVICE_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_care_service_change_status_publish_api/";

export const API_SHOP_QUICK_SUPPORT_INFO_GET_ALL = API_SHOP + "shop_quick_support_info_get_all_api/";
export const API_SHOP_QUICK_SUPPORT_INFO_ADD = API_SHOP + "shop_quick_support_info_add_api/";
export const API_SHOP_QUICK_SUPPORT_INFO_DELETE = API_SHOP + "shop_quick_support_info_delete_api/";
export const API_SHOP_QUICK_SUPPORT_INFO_GET_BY_ID = API_SHOP + "shop_quick_support_info_get_by_id_api/";
export const API_SHOP_QUICK_SUPPORT_INFO_EDIT = API_SHOP + "shop_quick_support_info_edit_api/";
export const API_SHOP_QUICK_SUPPORT_INFO_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_quick_support_info_change_status_publish_api/";

export const API_SHOP_CUSTOMER_REVIEW_GET_NOT_READ_ALL = API_SHOP + "shop_customer_review_get_all_not_read_api/";
export const API_SHOP_CUSTOMER_REVIEW_GET_READ_ALL = API_SHOP + "shop_customer_review_get_all_read_api/";
export const API_SHOP_CUSTOMER_REVIEW_ADD = API_SHOP + "shop_customer_review_add_api/";
export const API_SHOP_CUSTOMER_REVIEW_DELETE = API_SHOP + "shop_customer_review_delete_api/";
export const API_SHOP_CUSTOMER_REVIEW_GET_BY_ID = API_SHOP + "shop_customer_review_get_by_id_api/";
export const API_SHOP_CUSTOMER_REVIEW_EDIT = API_SHOP + "shop_customer_review_edit_api/";
export const API_SHOP_CUSTOMER_REVIEW_CHANGE_STATUS_READ = API_SHOP + "shop_customer_review_change_status_read_api/";

export const API_SHOP_MY_CUSTOMER_GET_ALL = API_SHOP + "shop_my_customer_get_all_api/";
export const API_SHOP_MY_CUSTOMER_ADD = API_SHOP + "shop_my_customer_add_api/";
export const API_SHOP_MY_CUSTOMER_DELETE = API_SHOP + "shop_my_customer_delete_api/";
export const API_SHOP_MY_CUSTOMER_GET_BY_ID = API_SHOP + "shop_my_customer_get_by_id_api/";
export const API_SHOP_MY_CUSTOMER_EDIT = API_SHOP + "shop_my_customer_edit_api/";
export const API_SHOP_MY_CUSTOMER_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_my_customer_change_status_publish_api/";

export const API_SHOP_PRODUCT_OF_CUSTOMER_GET_ALL = API_SHOP + "shop_product_of_customer_get_all_api/";
export const API_SHOP_PRODUCT_OF_CUSTOMER_ADD = API_SHOP + "shop_product_of_customer_add_api/";
export const API_SHOP_PRODUCT_OF_CUSTOMER_DELETE = API_SHOP + "shop_product_of_customer_delete_api/";
export const API_SHOP_PRODUCT_OF_CUSTOMER_GET_BY_ID = API_SHOP + "shop_product_of_customer_get_by_id_api/";
export const API_SHOP_PRODUCT_OF_CUSTOMER_EDIT = API_SHOP + "shop_product_of_customer_edit_api/";
export const API_SHOP_PRODUCT_OF_CUSTOMER_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_product_of_customer_change_status_publish_api/";

export const API_SHOP_QUESTION_AND_ANSWER_GET_ALL = API_SHOP + "shop_question_and_answer_get_all_api/";
export const API_SHOP_QUESTION_AND_ANSWER_ADD = API_SHOP + "shop_question_and_answer_add_api/";
export const API_SHOP_QUESTION_AND_ANSWER_DELETE = API_SHOP + "shop_question_and_answer_delete_api/";
export const API_SHOP_QUESTION_AND_ANSWER_GET_BY_ID = API_SHOP + "shop_question_and_answer_get_by_id_api/";
export const API_SHOP_QUESTION_AND_ANSWER_EDIT = API_SHOP + "shop_question_and_answer_edit_api/";
export const API_SHOP_QUESTION_AND_ANSWER_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_question_and_answer_change_status_publish_api/";

export const API_SHOP_OUR_TEAM_GET_ALL = API_SHOP + "shop_our_team_get_all_api/";
export const API_SHOP_OUR_TEAM_ADD = API_SHOP + "shop_our_team_add_api/";
export const API_SHOP_OUR_TEAM_DELETE = API_SHOP + "shop_our_team_delete_api/";
export const API_SHOP_OUR_TEAM_GET_BY_ID = API_SHOP + "shop_our_team_get_by_id_api/";
export const API_SHOP_OUR_TEAM_EDIT = API_SHOP + "shop_our_team_edit_api/";
export const API_SHOP_OUR_TEAM_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_our_team_change_status_publish_api/";

export const API_SHOP_GALLERY_VIDEO_CATEGORY_GET_ALL = API_SHOP + "shop_gallery_video_category_get_all_api/";
export const API_SHOP_GALLERY_VIDEO_CATEGORY_ADD = API_SHOP + "shop_gallery_video_category_add_api/";
export const API_SHOP_GALLERY_VIDEO_CATEGORY_DELETE = API_SHOP + "shop_gallery_video_category_delete_api/";
export const API_SHOP_GALLERY_VIDEO_CATEGORY_GET_BY_ID = API_SHOP + "shop_gallery_video_category_get_by_id_api/";
export const API_SHOP_GALLERY_VIDEO_CATEGORY_EDIT = API_SHOP + "shop_gallery_video_category_edit_api/";

export const API_SHOP_GALLERY_VIDEO_GET_ALL = API_SHOP + "shop_gallery_video_get_all_api/";
export const API_SHOP_GALLERY_VIDEO_ADD = API_SHOP + "shop_gallery_video_add_api/";
export const API_SHOP_GALLERY_VIDEO_DELETE = API_SHOP + "shop_gallery_video_delete_api/";
export const API_SHOP_GALLERY_VIDEO_GET_BY_ID = API_SHOP + "shop_gallery_video_get_by_id_api/";
export const API_SHOP_GALLERY_VIDEO_EDIT = API_SHOP + "shop_gallery_video_edit_api/";

export const API_SHOP_GALLERY_IMAGE_CATEGORY_GET_ALL = API_SHOP + "shop_gallery_image_category_get_all_api/";
export const API_SHOP_GALLERY_IMAGE_CATEGORY_ADD = API_SHOP + "shop_gallery_image_category_add_api/";
export const API_SHOP_GALLERY_IMAGE_CATEGORY_DELETE = API_SHOP + "shop_gallery_image_category_delete_api/";
export const API_SHOP_GALLERY_IMAGE_CATEGORY_GET_BY_ID = API_SHOP + "shop_gallery_image_category_get_by_id_api/";
export const API_SHOP_GALLERY_IMAGE_CATEGORY_EDIT = API_SHOP + "shop_gallery_image_category_edit_api/";

export const API_SHOP_GALLERY_IMAGE_GET_ALL = API_SHOP + "shop_gallery_image_get_all_api/";
export const API_SHOP_GALLERY_IMAGE_ADD = API_SHOP + "shop_gallery_image_add_api/";
export const API_SHOP_GALLERY_IMAGE_DELETE = API_SHOP + "shop_gallery_image_delete_api/";
export const API_SHOP_GALLERY_IMAGE_GET_BY_ID = API_SHOP + "shop_gallery_image_get_by_id_api/";
export const API_SHOP_GALLERY_IMAGE_EDIT = API_SHOP + "shop_gallery_image_edit_api/";

export const API_SHOP_MY_PORTFOLIO_GET_ALL = API_SHOP + "shop_my_portfolio_get_all_api/";
export const API_SHOP_MY_PORTFOLIO_ADD = API_SHOP + "shop_my_portfolio_add_api/";
export const API_SHOP_MY_PORTFOLIO_DELETE = API_SHOP + "shop_my_portfolio_delete_api/";
export const API_SHOP_MY_PORTFOLIO_GET_BY_ID = API_SHOP + "shop_my_portfolio_get_by_id_api/";
export const API_SHOP_MY_PORTFOLIO_EDIT = API_SHOP + "shop_my_portfolio_edit_api/";
export const API_SHOP_MY_PORTFOLIO_CHANGE_STATUS_PUBLISH = API_SHOP + "shop_my_portfolio_change_status_publish_api/";

// COLLABORATOR

export const API_COLLABORATOR = URL_SITE + "collaborator/"
export const API_COLLABORATOR_INTRODUCE_CUSTOMER_GET_NOT_READ_ALL = API_COLLABORATOR + "collaborator_introduce_customer_get_all_not_read_api/";
export const API_COLLABORATOR_INTRODUCE_CUSTOMER_GET_READ_ALL = API_COLLABORATOR + "collaborator_introduce_customer_get_all_read_api/";
export const API_COLLABORATOR_INTRODUCE_CUSTOMER_ADD = API_COLLABORATOR + "collaborator_introduce_customer_add_api/";
export const API_COLLABORATOR_INTRODUCE_CUSTOMER_DELETE = API_COLLABORATOR + "collaborator_introduce_customer_delete_api/";
export const API_COLLABORATOR_INTRODUCE_CUSTOMER_GET_BY_ID = API_COLLABORATOR + "collaborator_introduce_customer_get_by_id_api/";
export const API_COLLABORATOR_INTRODUCE_CUSTOMER_EDIT = API_COLLABORATOR + "collaborator_introduce_customer_edit_api/";
export const API_COLLABORATOR_INTRODUCE_CUSTOMER_CHANGE_STATUS_READ = API_COLLABORATOR + "collaborator_introduce_customer_change_status_read_api/";

export const API_COLLABORATOR_ME_GET_NOT_READ_ALL = API_COLLABORATOR + "collaborator_me_get_all_not_read_api/";
export const API_COLLABORATOR_ME_GET_READ_ALL = API_COLLABORATOR + "collaborator_me_get_all_read_api/";
export const API_COLLABORATOR_ME_GET_BY_USER_ID = API_COLLABORATOR + "collaborator_me_get_by_user_id_api/";
export const API_COLLABORATOR_ME_ADD = API_COLLABORATOR + "collaborator_me_add_api/";
export const API_COLLABORATOR_ME_DELETE = API_COLLABORATOR + "collaborator_me_delete_api/";
export const API_COLLABORATOR_ME_GET_BY_ID = API_COLLABORATOR + "collaborator_me_get_by_id_api/";
export const API_COLLABORATOR_ME_EDIT = API_COLLABORATOR + "collaborator_me_edit_api/";
export const API_COLLABORATOR_ME_CHANGE_STATUS_READ = API_COLLABORATOR + "collaborator_me_change_status_read_api/";
export const API_COLLABORATOR_ME_ASSIGN_TO_USER = API_COLLABORATOR + "collaborator_me_assign_to_user_api/";

export const API_COLLABORATOR_DESIGNER_GET_NOT_READ_ALL = API_COLLABORATOR + "collaborator_designer_get_all_not_read_api/";
export const API_COLLABORATOR_DESIGNER_GET_READ_ALL = API_COLLABORATOR + "collaborator_designer_get_all_read_api/";
export const API_COLLABORATOR_DESIGNER_ADD = API_COLLABORATOR + "collaborator_designer_add_api/";
export const API_COLLABORATOR_DESIGNER_DELETE = API_COLLABORATOR + "collaborator_designer_delete_api/";
export const API_COLLABORATOR_DESIGNER_GET_BY_ID = API_COLLABORATOR + "collaborator_designer_get_by_id_api/";
export const API_COLLABORATOR_DESIGNER_EDIT = API_COLLABORATOR + "collaborator_designer_edit_api/";
export const API_COLLABORATOR_DESIGNER_CHANGE_STATUS_READ = API_COLLABORATOR + "collaborator_designer_change_status_read_api/";

export const API_COLLABORATOR_ORDER_GET_NOT_PAID_ALL = API_COLLABORATOR + "collaborator_order_get_all_not_paid_api/";
export const API_COLLABORATOR_ORDER_GET_PAID_ALL = API_COLLABORATOR + "collaborator_order_get_all_paid_api/";
export const API_COLLABORATOR_ORDER_GET_NOT_PAID_BY_COLLABORATOR_ME_ID_ALL = API_COLLABORATOR + "collaborator_order_get_all_not_paid_by_collaborator_me_id_api/";
export const API_COLLABORATOR_ORDER_GET_PAID_BY_COLLABORATOR_ME_ID_ALL = API_COLLABORATOR + "collaborator_order_get_all_paid_by_collaborator_me_id_api/";
export const API_COLLABORATOR_ORDER_GET_NOT_PAID_BY_USER_ID_ALL = API_COLLABORATOR + "collaborator_order_get_all_not_paid_by_user_id_api/";
export const API_COLLABORATOR_ORDER_GET_PAID_BY_USER_ID_ALL = API_COLLABORATOR + "collaborator_order_get_all_paid_by_user_id_api/";
export const API_COLLABORATOR_ORDER_ADD = API_COLLABORATOR + "collaborator_order_add_api/";
export const API_COLLABORATOR_ORDER_DELETE = API_COLLABORATOR + "collaborator_order_delete_api/";
export const API_COLLABORATOR_ORDER_GET_BY_ID = API_COLLABORATOR + "collaborator_order_get_by_id_api/";
export const API_COLLABORATOR_ORDER_EDIT = API_COLLABORATOR + "collaborator_order_edit_api/";
export const API_COLLABORATOR_ORDER_CHANGE_STATUS_PAID = API_COLLABORATOR + "collaborator_order_change_status_paid_api/";

export const API_COLLABORATOR_STATIC_PAGE_GET_ALL = API_COLLABORATOR + "collaborator_static_page_get_all_api/";
export const API_COLLABORATOR_STATIC_PAGE_ADD = API_COLLABORATOR + "collaborator_static_page_add_api/";
export const API_COLLABORATOR_STATIC_PAGE_DELETE = API_COLLABORATOR + "collaborator_static_page_delete_api/";
export const API_COLLABORATOR_STATIC_PAGE_GET_BY_ID = API_COLLABORATOR + "collaborator_static_page_get_by_id_api/";
export const API_COLLABORATOR_STATIC_PAGE_EDIT = API_COLLABORATOR + "collaborator_static_page_edit_api/";
export const API_COLLABORATOR_STATIC_PAGE_CHANGE_STATUS_PUBLISH = API_COLLABORATOR + "collaborator_static_page_change_status_publish_api/";

export const API_COLLABORATOR_REVIEW_GET_ALL = API_COLLABORATOR + "collaborator_review_get_all_api/";
export const API_COLLABORATOR_REVIEW_ADD = API_COLLABORATOR + "collaborator_review_add_api/";
export const API_COLLABORATOR_REVIEW_DELETE = API_COLLABORATOR + "collaborator_review_delete_api/";
export const API_COLLABORATOR_REVIEW_GET_BY_ID = API_COLLABORATOR + "collaborator_review_get_by_id_api/";
export const API_COLLABORATOR_REVIEW_EDIT = API_COLLABORATOR + "collaborator_review_edit_api/";
export const API_COLLABORATOR_REVIEW_CHANGE_STATUS_PUBLISH = API_COLLABORATOR + "collaborator_review_change_status_publish_api/";

export const API_COLLABORATOR_FILTER_USER = API_COLLABORATOR + "user_filter_api/";

// Setting
export const API_SETTING_BANCLICKMOUSE_GET = URL_SITE + "setting/get_setting_banclickmouse_view/";

// Static
export const API_SEND_MESSAGE_CONTACT_POST = URL_SITE + "static/send_contact_api/";
export const API_ABOUT_GET = URL_SITE + "static/get_about_api/";
export const API_PRIVACY_POLICY_GET = URL_SITE + "static/get_privacy_policy_api/";
export const API_TERM_OF_USE_GET = URL_SITE + "static/get_term_of_use_api/";
export const API_PAYMENT_POLICY_GET = URL_SITE + "static/get_payment_policy_api/";
export const API_GUARANTEE_POLICY_GET = URL_SITE + "static/get_guarantee_policy_api/";

// Course 
export const API_ADD_COMMENT_VIDEO_BY_USER_ID_POST = URL_SITE + "courses/add_comment_video_by_user_api/";
export const API_LIKE_VIDEO_BY_USER_ID_PATCH = URL_SITE + "courses/patch_like_video_by_user_api/";
export const API_VIDEO_DETAIL_LIKE_BY_USER_ID_AND_VIDEO_ID_GET = URL_SITE + "courses/get_video_detail_like_by_user_id_and_video_id_api/";
export const API_COMMENT_VIDEO_BY_USER_ID_DELETE = URL_SITE + "courses/delete_comment_video_by_user_api/";
export const API_VIDEO_DETAIL_COMMENT_BY_USER_ID_AND_VIDEO_ID_GET = URL_SITE + "courses/get_video_detail_comment_by_user_id_and_video_id_api/";
export const API_MY_COURSE_BY_USER_ID_AND_SLUG_GET = URL_SITE + "courses/get_my_course_by_user_and_slug_api/";
export const API_COURSE_FREE_ALL_GET = URL_SITE + "courses/get_course_free_all_api/";
export const API_COURSE_FEE_ALL_GET = URL_SITE + "courses/get_course_fee_all_api/";
export const API_COURSE_DETAIL_GET = URL_SITE + "courses/get_course_detail_by_slug_api/";
export const API_VIDEO_CATEGORY_BY_COURSE_CATEGORY_SLUG_GET = URL_SITE + "courses/get_video_category_by_course_category_slug_api/";
// 
export const API_COURSE_CATEGORY_LEVEL_2_GET_ALL = URL_SITE + "course/course_category_level_2_get_all_api/";

// StudentMe
export const API_STUDENT_ME_GET_ALL = URL_SITE + "student/student_me_get_all_api/";
export const API_STUDENT_ME_ADD = URL_SITE + "student/student_me_add_api/";
export const API_STUDENT_ME_DELETE = URL_SITE + "student/student_me_delete_api/";
export const API_STUDENT_ME_GET_BY_ID = URL_SITE + "student/student_me_get_by_id_api/";
export const API_STUDENT_ME_EDIT = URL_SITE + "student/student_me_edit_api/";
export const API_STUDENT_ME_CHANGE_STATUS_IS_ACTIVE = URL_SITE + "student/student_me_change_status_is_active_api/";
// StudentCourse
export const API_STUDENT_COURSE_GET_ALL_BY_STUDENT_ME_ID = URL_SITE + "student/student_course_get_all_by_student_me_id_api/";
export const API_STUDENT_COURSE_GET_ALL = URL_SITE + "student/student_course_get_all_api/";
export const API_STUDENT_COURSE_ADD = URL_SITE + "student/student_course_add_api/";
export const API_STUDENT_COURSE_DELETE = URL_SITE + "student/student_course_delete_api/";
export const API_STUDENT_COURSE_GET_BY_ID = URL_SITE + "student/student_course_get_by_id_api/";
export const API_STUDENT_COURSE_EDIT = URL_SITE + "student/student_course_edit_api/";
// My Course 
export const API_STUDENT_MY_COURSE_GET_ALL_BY_USER_ID = URL_SITE + "student/student_course_get_all_by_user_id_api/";
// StudentCourseComment
export const API_STUDENT_COURSE_COMMENT_GET_ALL_BY_USER_ID = URL_SITE + "student/student_course_comment_get_all_by_user_id_api/";
export const API_STUDENT_COURSE_COMMENT_GET_ALL_BY_COURSE_INDEX_LEVEL2_ID = URL_SITE + "student/student_course_comment_get_all_by_course_index_level2_id_api/";
export const API_STUDENT_COURSE_COMMENT_GET_ALL_BY_STUDENT_COURSE_ID_AND_COURSE_INDEX_LEVEL2_ID = URL_SITE + "student/student_course_comment_get_all_by_student_course_id_and_course_index_level2_id_api/";
export const API_STUDENT_COURSE_COMMENT_GET_ALL_BY_STUDENT_ME_ID = URL_SITE + "student/student_course_comment_get_all_by_student_me_id_api/";
export const API_STUDENT_COURSE_COMMENT_GET_ALL = URL_SITE + "student/student_course_comment_get_all_api/";
export const API_STUDENT_COURSE_COMMENT_ADD = URL_SITE + "student/student_course_comment_add_api/";
export const API_STUDENT_COURSE_COMMENT_DELETE = URL_SITE + "student/student_course_comment_delete_api/";
export const API_STUDENT_COURSE_COMMENT_GET_BY_ID = URL_SITE + "student/student_course_comment_get_by_id_api/";
export const API_STUDENT_COURSE_COMMENT_EDIT = URL_SITE + "student/student_course_comment_edit_api/";
// StudentCourseCommentReply
export const API_STUDENT_COURSE_COMMENT_REPLY_ADD = URL_SITE + "student/student_course_comment_reply_add_api/";
export const API_STUDENT_COURSE_COMMENT_REPLY_DELETE = URL_SITE + "student/student_course_comment_reply_delete_api/";
export const API_STUDENT_COURSE_COMMENT_REPLY_GET_BY_ID = URL_SITE + "student/student_course_comment_reply_get_by_id_api/";
export const API_STUDENT_COURSE_COMMENT_REPLY_EDIT = URL_SITE + "student/student_course_comment_reply_edit_api/";
// StudentCourseTracking
export const API_STUDENT_COURSE_TRACKING_REPLY_ADD = URL_SITE + "student/student_course_tracking_add_api/";
export const API_STUDENT_COURSE_TRACKING_EDIT_PERCENT_COMPLETE = URL_SITE + "student/student_course_tracking_edit_percent_complete_api/";


// Payment 
export const API_PAYMENT_HISTORY_BY_USER_ID_GET = URL_SITE + "payments/get_payment_history_by_user_api/";
export const API_MY_COMPLETED_COURSES_BY_USER_ID_GET = URL_SITE + "payments/get_my_completed_courses_by_user_api/";
export const API_MY_NEW_COURSES_BY_USER_ID_GET = URL_SITE + "payments/get_my_new_courses_by_user_api/";

// User Profile
export const API_CHANGE_PASSWORD_POST = URL_SITE + "account/change-password/";
export const API_UPDATE_PROFILE_POST = URL_SITE + "account/update-user-profile/";
export const API_USER_PROFILE_GET = URL_SITE + "account/get-user-profile/";
export const API_USER_HISTORY_LOG_GET = URL_SITE + "account/get-history-log/";
export const API_USER_HISTORY_LOG_GET_ALL = URL_SITE + "account/history-log-get-all/";
export const API_UPLOAD_AVATAR_PROFILE_PATCH = URL_SITE + "account/upload-avatar-user/";
export const API_PROFILE_CHECK_EXIST_POST = URL_SITE + "account/profile-check-exist/";
export const API_PROFILE_ADD = URL_SITE + "account/profile-add/";

// Auth
export const API_RESET_PASSWORD_POST = URL_SITE + "auth/reset-password/";
export const API_ACTIVE_ACCOUNT_POST = URL_SITE + "auth/active-account/";
export const API_RESEND_LINK_ACTIVATION_POST = URL_SITE + "auth/resend-link-active/";
export const API_FORGOT_PASSWORD_POST = URL_SITE + "auth/forgot-password/";
export const API_SIGNUP_POST = URL_SITE + "auth/register/";
export const API_LOGIN_POST = URL_SITE + "auth/login/";
export const API_LOGIN_GOOGLE_POST = URL_SITE + "auth/google/";


// ===================================================

export const URL_WAREHOUSE = URL_SITE + "warehouse/";
export const API_WAREHOUSE_GET_ALL_CATEGORY_PRODUCT_LEVEL_1 = URL_WAREHOUSE + "category_product_level_1_get_all_api/";
export const API_WAREHOUSE_ADD_ORDER_INPUT = URL_WAREHOUSE + "add_order_input_api/";
export const API_WAREHOUSE_GET_ALL_ORDER_INPUT = URL_WAREHOUSE + "get_all_order_input_api/";
export const API_WAREHOUSE_GET_ORDER_INPUT_BY_ID = URL_WAREHOUSE + "get_order_input_by_id_api/";
export const API_WAREHOUSE_EDIT_ORDER_INPUT = URL_WAREHOUSE + "edit_order_input_api/";

export const URL_SYSTEM_URL = URL_SITE + "system/";
export const API_SYSTEM_GET_ALL_CATEGORY_IMAGE_LIBRARY = URL_SYSTEM_URL + "image_library_category_get_all_api/";
export const API_SYSTEM_GET_ALL_IMAGES_BY_CATEGORY_ID = URL_SYSTEM_URL + "image_library_get_all_images_by_category_api/";
export const API_SYSTEM_DELETE_IMAGE_FROM_IMAGE_LIBRARY = URL_SYSTEM_URL + "image_library_delete_image_api/";
export const API_SYSTEM_UPDATE_IMAGE_FROM_IMAGE_LIBRARY = URL_SYSTEM_URL + "image_library_update_image_api/";
export const API_SYSTEM_ADD_CATEGORY_IMAGE_LIBRARY = URL_SYSTEM_URL + "add_image_library_category_api/";
export const API_SYSTEM_EDIT_CATEGORY_IMAGE_LIBRARY = URL_SYSTEM_URL + "edit_image_library_category_api/";
export const API_SYSTEM_DELETE_CATEGORY_IMAGE_LIBRARY = URL_SYSTEM_URL + "delete_image_library_category_api/";
export const API_SYSTEM_UPLOAD_IMAGE_LIBRARY_FOR_CATEGORY = URL_SYSTEM_URL + "upload_image_library_for_category_api/";
// 
export const API_SYSTEM_GET_ALL_CATEGORY_IMAGE_LIBRARY_PROTECTED = URL_SYSTEM_URL + "image_library_protected_category_get_all_api/";
export const API_SYSTEM_GET_ALL_IMAGES_PROTECTED_BY_CATEGORY_ID = URL_SYSTEM_URL + "image_library_protected_get_all_images_by_category_api/";
export const API_SYSTEM_DELETE_IMAGE_FROM_IMAGE_LIBRARY_PROTECTED = URL_SYSTEM_URL + "image_library_protected_delete_image_api/";
export const API_SYSTEM_UPDATE_IMAGE_FROM_IMAGE_LIBRARY_PROTECTED = URL_SYSTEM_URL + "image_library_protected_update_image_api/";
export const API_SYSTEM_ADD_CATEGORY_IMAGE_LIBRARY_PROTECTED = URL_SYSTEM_URL + "add_image_library_protected_category_api/";
export const API_SYSTEM_EDIT_CATEGORY_IMAGE_LIBRARY_PROTECTED = URL_SYSTEM_URL + "edit_image_library_protected_category_api/";
export const API_SYSTEM_DELETE_CATEGORY_IMAGE_LIBRARY_PROTECTED = URL_SYSTEM_URL + "delete_image_library_protected_category_api/";
export const API_SYSTEM_UPLOAD_IMAGE_LIBRARY_PROTECTED_FOR_CATEGORY = URL_SYSTEM_URL + "upload_image_library_protected_for_category_api/";
// 
export const API_SYSTEM_GET_ALL_CATEGORY_DOCUMENT_LIBRARY = URL_SYSTEM_URL + "document_library_category_get_all_api/";
export const API_SYSTEM_GET_ALL_DOCUMENTS_BY_CATEGORY_ID = URL_SYSTEM_URL + "document_library_get_all_documents_by_category_api/";
export const API_SYSTEM_DELETE_DOCUMENT_FROM_DOCUMENT_LIBRARY = URL_SYSTEM_URL + "document_library_delete_document_api/";
export const API_SYSTEM_UPDATE_DOCUMENT_FROM_DOCUMENT_LIBRARY = URL_SYSTEM_URL + "document_library_update_document_api/";
export const API_SYSTEM_ADD_CATEGORY_DOCUMENT_LIBRARY = URL_SYSTEM_URL + "add_document_library_category_api/";
export const API_SYSTEM_EDIT_CATEGORY_DOCUMENT_LIBRARY = URL_SYSTEM_URL + "edit_document_library_category_api/";
export const API_SYSTEM_DELETE_CATEGORY_DOCUMENT_LIBRARY = URL_SYSTEM_URL + "delete_document_library_category_api/";
export const API_SYSTEM_UPLOAD_DOCUMENT_LIBRARY_FOR_CATEGORY = URL_SYSTEM_URL + "upload_document_library_for_category_api/";
// System
export const API_CHECK_LOCK_LOGIN_SYSTEM = URL_SYSTEM_URL + "check_is_lock_login/";

// ==========================================================================================

// COURSE
export const API_COURSE = URL_SITE + "course/"
export const API_COURSE_CATEGORY_LEVEL_1_GET_ALL = API_COURSE + "course_category_level_1_get_all_api/";
export const API_COURSE_CATEGORY_LEVEL_1_ADD = API_COURSE + "course_category_level_1_add_api/";
export const API_COURSE_CATEGORY_LEVEL_1_DELETE = API_COURSE + "course_category_level_1_delete_api/";
export const API_COURSE_CATEGORY_LEVEL_1_GET_BY_ID = API_COURSE + "course_category_level_1_get_by_id_api/";
export const API_COURSE_CATEGORY_LEVEL_1_EDIT = API_COURSE + "course_category_level_1_edit_api/";
export const API_COURSE_CATEGORY_LEVEL_1_CHANGE_STATUS_PUBLISH = API_COURSE + "course_category_level_1_change_status_publish_api/";

export const API_COURSE_CATEGORY_LEVEL_2_ADD = API_COURSE + "course_category_level_2_add_api/";
export const API_COURSE_CATEGORY_LEVEL_2_DELETE = API_COURSE + "course_category_level_2_delete_api/";
export const API_COURSE_CATEGORY_LEVEL_2_GET_BY_ID = API_COURSE + "course_category_level_2_get_by_id_api/";
export const API_COURSE_CATEGORY_LEVEL_2_EDIT = API_COURSE + "course_category_level_2_edit_api/";
export const API_COURSE_CATEGORY_LEVEL_2_GET_ALL_BY_ID_CATEGORY_LEVEL_1 = API_COURSE + "course_category_level_2_get_all_by_id_category_level_1_api/";
export const API_COURSE_CATEGORY_LEVEL_2_GET_ALL_WITH_POST = API_COURSE + "course_category_level_2_get_all_with_post_api/";
export const API_COURSE_CATEGORY_LEVEL_2_CHANGE_STATUS_PUBLISH = API_COURSE + "course_category_level_2_change_status_publish_api/";
export const API_COURSE_CATEGORY_LEVEL_2_CHANGE_STATUS_IS_FREE = API_COURSE + "course_category_level_2_change_status_is_free_api/";
export const API_COURSE_CATEGORY_LEVEL_2_CHANGE_STATUS_IS_WEBINAR = API_COURSE + "course_category_level_2_change_status_is_webinar_api/";
// 
export const API_COURSE_CATEGORY_LEVEL_2_GET_ALL_BY_SLUG = API_COURSE + "course_category_level_2_get_all_by_slug_api/";
// 
export const API_COURSE_COMMENT_GET_ALL_BY_COURSE_CATEGORY_LEVEL2 = API_COURSE + "course_comment_get_all_by_course_category_level2_api/";
export const API_COURSE_COMMENT_ADD = API_COURSE + "course_comment_add_api/";
export const API_COURSE_COMMENT_DELETE = API_COURSE + "course_comment_delete_api/";
export const API_COURSE_COMMENT_GET_BY_ID = API_COURSE + "course_comment_get_by_id_api/";
export const API_COURSE_COMMENT_EDIT = API_COURSE + "course_comment_edit_api/";
export const API_COURSE_COMMENT_CHANGE_STATUS_PUBLISH = API_COURSE + "course_comment_change_status_publish_api/";

export const API_COURSE_INDEX_LEVEL_1_GET_ALL_BY_COURSE_CATEGORY_LEVEL2 = API_COURSE + "course_index_level_1_get_all_by_course_category_level2_api/";
export const API_COURSE_INDEX_LEVEL_1_ADD = API_COURSE + "course_index_level_1_add_api/";
export const API_COURSE_INDEX_LEVEL_1_DELETE = API_COURSE + "course_index_level_1_delete_api/";
export const API_COURSE_INDEX_LEVEL_1_GET_BY_ID = API_COURSE + "course_index_level_1_get_by_id_api/";
export const API_COURSE_INDEX_LEVEL_1_EDIT = API_COURSE + "course_index_level_1_edit_api/";
export const API_COURSE_INDEX_LEVEL_1_CHANGE_STATUS_PUBLISH = API_COURSE + "course_index_level_1_change_status_publish_api/";
export const API_COURSE_INDEX_LEVEL_1_CHANGE_STATUS_IS_FREE = API_COURSE + "course_index_level_1_change_status_is_free_api/";

export const API_COURSE_INDEX_LEVEL_2_ADD = API_COURSE + "course_index_level_2_add_api/";
export const API_COURSE_INDEX_LEVEL_2_DELETE = API_COURSE + "course_index_level_2_delete_api/";
export const API_COURSE_INDEX_LEVEL_2_GET_BY_ID = API_COURSE + "course_index_level_2_get_by_id_api/";
export const API_COURSE_INDEX_LEVEL_2_EDIT = API_COURSE + "course_index_level_2_edit_api/";
export const API_COURSE_INDEX_LEVEL_2_GET_ALL_BY_ID_CATEGORY_LEVEL_1 = API_COURSE + "course_index_level_2_get_all_by_id_category_level_1_api/";
export const API_COURSE_INDEX_LEVEL_2_GET_ALL_WITH_POST = API_COURSE + "course_index_level_2_get_all_with_post_api/";
export const API_COURSE_INDEX_LEVEL_2_CHANGE_STATUS_PUBLISH = API_COURSE + "course_index_level_2_change_status_publish_api/";
export const API_COURSE_INDEX_LEVEL_2_CHANGE_STATUS_IS_FREE = API_COURSE + "course_index_level_2_change_status_is_free_api/";

export const API_COURSE_REGISTER_WEBINAR_GET_NOT_READ_ALL = API_COURSE + "course_register_webinar_get_all_not_read_api/";
export const API_COURSE_REGISTER_WEBINAR_GET_READ_ALL = API_COURSE + "course_register_webinar_get_all_read_api/";
export const API_COURSE_REGISTER_WEBINAR_ADD = API_COURSE + "course_register_webinar_add_api/";
export const API_COURSE_REGISTER_WEBINAR_DELETE = API_COURSE + "course_register_webinar_delete_api/";
export const API_COURSE_REGISTER_WEBINAR_GET_BY_ID = API_COURSE + "course_register_webinar_get_by_id_api/";
export const API_COURSE_REGISTER_WEBINAR_EDIT = API_COURSE + "course_register_webinar_edit_api/";
export const API_COURSE_REGISTER_WEBINAR_EDIT_FULL_NAME = API_COURSE + "course_register_webinar_edit_full_name_api/";
export const API_COURSE_REGISTER_WEBINAR_CHANGE_STATUS_READ = API_COURSE + "course_register_webinar_change_status_read_api/";

export const API_COURSE_QUESTION_AND_ANSWER_GET_NOT_READ_ALL = API_COURSE + "course_question_and_answer_get_all_not_read_api/";
export const API_COURSE_QUESTION_AND_ANSWER_GET_READ_ALL = API_COURSE + "course_question_and_answer_get_all_read_api/";
export const API_COURSE_QUESTION_AND_ANSWER_ADD = API_COURSE + "course_question_and_answer_add_api/";
export const API_COURSE_QUESTION_AND_ANSWER_DELETE = API_COURSE + "course_question_and_answer_delete_api/";
export const API_COURSE_QUESTION_AND_ANSWER_GET_BY_ID = API_COURSE + "course_question_and_answer_get_by_id_api/";
export const API_COURSE_QUESTION_AND_ANSWER_EDIT = API_COURSE + "course_question_and_answer_edit_api/";
export const API_COURSE_QUESTION_AND_ANSWER_EDIT_FULL_NAME = API_COURSE + "course_question_and_answer_edit_full_name_api/";
export const API_COURSE_QUESTION_AND_ANSWER_CHANGE_STATUS_READ = API_COURSE + "course_question_and_answer_change_status_read_api/";

export const API_CITY_IN_VIETNAM_GET_ALL = API_COURSE + "city_in_vietnam_get_all_api/";

export const API_SCHOOL_IT_IN_VIETNAM_GET_ALL = API_COURSE + "school_it_in_vietnam_get_all_api/";
export const API_SCHOOL_IT_IN_VIETNAM_ADD = API_COURSE + "school_it_in_vietnam_add_api/";
export const API_SCHOOL_IT_IN_VIETNAM_DELETE = API_COURSE + "school_it_in_vietnam_delete_api/";
export const API_SCHOOL_IT_IN_VIETNAM_GET_BY_ID = API_COURSE + "school_it_in_vietnam_get_by_id_api/";
export const API_SCHOOL_IT_IN_VIETNAM_EDIT = API_COURSE + "school_it_in_vietnam_edit_api/";
export const API_SCHOOL_IT_IN_VIETNAM_CHANGE_STATUS_PUBLISH = API_COURSE + "school_it_in_vietnam_change_status_publish_api/";

export const API_COMPANY_IT_IN_VIETNAM_GET_ALL = API_COURSE + "company_it_in_vietnam_get_all_api/";
export const API_COMPANY_IT_IN_VIETNAM_ADD = API_COURSE + "company_it_in_vietnam_add_api/";
export const API_COMPANY_IT_IN_VIETNAM_DELETE = API_COURSE + "company_it_in_vietnam_delete_api/";
export const API_COMPANY_IT_IN_VIETNAM_GET_BY_ID = API_COURSE + "company_it_in_vietnam_get_by_id_api/";
export const API_COMPANY_IT_IN_VIETNAM_EDIT = API_COURSE + "company_it_in_vietnam_edit_api/";
export const API_COMPANY_IT_IN_VIETNAM_CHANGE_STATUS_PUBLISH = API_COURSE + "company_it_in_vietnam_change_status_publish_api/";

export const API_MARKET_IT_IN_VIETNAM_GET_ALL = API_COURSE + "market_it_in_vietnam_get_all_api/";
export const API_MARKET_IT_IN_VIETNAM_ADD = API_COURSE + "market_it_in_vietnam_add_api/";
export const API_MARKET_IT_IN_VIETNAM_DELETE = API_COURSE + "market_it_in_vietnam_delete_api/";
export const API_MARKET_IT_IN_VIETNAM_GET_BY_ID = API_COURSE + "market_it_in_vietnam_get_by_id_api/";
export const API_MARKET_IT_IN_VIETNAM_EDIT = API_COURSE + "market_it_in_vietnam_edit_api/";
export const API_MARKET_IT_IN_VIETNAM_CHANGE_STATUS_PUBLISH = API_COURSE + "market_it_in_vietnam_change_status_publish_api/";

export const API_MARKET_IT_IN_ABROAD_GET_ALL = API_COURSE + "market_it_in_abroad_get_all_api/";
export const API_MARKET_IT_IN_ABROAD_ADD = API_COURSE + "market_it_in_abroad_add_api/";
export const API_MARKET_IT_IN_ABROAD_DELETE = API_COURSE + "market_it_in_abroad_delete_api/";
export const API_MARKET_IT_IN_ABROAD_GET_BY_ID = API_COURSE + "market_it_in_abroad_get_by_id_api/";
export const API_MARKET_IT_IN_ABROAD_EDIT = API_COURSE + "market_it_in_abroad_edit_api/";
export const API_MARKET_IT_IN_ABROAD_CHANGE_STATUS_PUBLISH = API_COURSE + "market_it_in_abroad_change_status_publish_api/";

// SKILL ENGLISH
export const API_SKILL = URL_SITE + "english_skill/"
export const API_ENGLISH_SKILL_BY_TOPIC_CATEGORY_LEVEL_1_GET_ALL = API_SKILL + "english_skill_by_topic_category_level_1_get_all_api/";
export const API_ENGLISH_SKILL_EXERCISE_BY_TOPIC_GET_ALL_BY_ID_CATEGORY_LEVEL_2 = API_SKILL + "english_skill_exercise_by_topic_get_all_by_id_category_level_2_api/";
export const API_ENGLISH_SKILL_BY_DEMAND_CATEGORY_LEVEL_1_GET_ALL = API_SKILL + "english_skill_by_demand_category_level_1_get_all_api/";
export const API_ENGLISH_SKILL_EXERCISE_BY_DEMAND_GET_ALL_BY_ID_CATEGORY_LEVEL_2 = API_SKILL + "english_skill_exercise_by_demand_get_all_by_id_category_level_2_api/";

// SKILL SOFT
export const API_SOFT_SKILL = URL_SITE + "soft_skill/"
export const API_SOFT_SKILL_FOUNDATION_CATEGORY_LEVEL_1_GET_ALL = API_SOFT_SKILL + "soft_skill_foundation_category_level_1_get_all_api/";
export const API_SOFT_SKILL_FOUNDATION_KNOWLEDGE_GET_ALL_BY_ID_CATEGORY_LEVEL_2 = API_SOFT_SKILL + "soft_skill_foundation_knowledge_get_all_by_id_category_level_2_api/";
export const API_SOFT_SKILL_PRACTICING_CATEGORY_LEVEL_1_GET_ALL = API_SOFT_SKILL + "soft_skill_practicing_category_level_1_get_all_api/";
export const API_SOFT_SKILL_PRACTICING_BY_LESSON_GET_ALL_BY_ID_CATEGORY_LEVEL_2 = API_SOFT_SKILL + "soft_skill_practicing_get_all_by_id_category_level_2_api/";

// SKILL INTERVIEW
export const API_INTERVIEW_SKILL = URL_SITE + "interview_skill/"
export const API_INTERVIEW_SKILL_MENTOR_COURSE_GET_ALL_BY_USER_ME_ID = API_INTERVIEW_SKILL + "mentor_course_get_all_by_user_me_id_api/";

// SKILL INTERVIEW FROM MENTOR
export const API_INTERVIEW_SKILL_MENTOR_COURSE_CONTENT_GET_ALL = API_INTERVIEW_SKILL + "mentor_course_interview_content_get_all_api/";
export const API_INTERVIEW_SKILL_MENTOR_COURSE_CONTENT_GET_ALL_BY_MENTOR_SINGLE_COURSE_ID = API_INTERVIEW_SKILL + "mentor_course_interview_content_get_all_by_mentor_single_course_id_api/";
export const API_INTERVIEW_SKILL_MENTOR_COURSE_CONTENT_ADD = API_INTERVIEW_SKILL + "mentor_course_interview_content_add_api/";
export const API_INTERVIEW_SKILL_MENTOR_COURSE_CONTENT_DELETE = API_INTERVIEW_SKILL + "mentor_course_interview_content_delete_api/";
export const API_INTERVIEW_SKILL_MENTOR_COURSE_CONTENT_GET_BY_ID = API_INTERVIEW_SKILL + "mentor_course_interview_content_get_by_id_api/";
export const API_INTERVIEW_SKILL_MENTOR_COURSE_CONTENT_EDIT = API_INTERVIEW_SKILL + "mentor_course_interview_content_edit_api/";
export const API_INTERVIEW_SKILL_MENTOR_COURSE_CONTENT_CHANGE_STATUS_PUBLISH = API_INTERVIEW_SKILL + "mentor_course_interview_content_change_status_publish_api/";

// ==========================================================

// SKILL ENGLISH BY TOPIC
export const API_SKILL_FREE = URL_SITE + "english_skill_free/"
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_1_GET_ALL = API_SKILL_FREE + "english_skill_by_topic_category_level_1_free_get_all_api/";
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_1_ADD = API_SKILL_FREE + "english_skill_by_topic_category_level_1_free_add_api/";
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_1_DELETE = API_SKILL_FREE + "english_skill_by_topic_category_level_1_free_delete_api/";
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_1_GET_BY_ID = API_SKILL_FREE + "english_skill_by_topic_category_level_1_free_get_by_id_api/";
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_1_EDIT = API_SKILL_FREE + "english_skill_by_topic_category_level_1_free_edit_api/";
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_1_CHANGE_STATUS_IS_DISPLAY = API_SKILL_FREE + "english_skill_by_topic_category_level_1_free_change_status_is_display_api/";
// 
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_1_GET_ALL_NOT_AUTH = API_SKILL_FREE + "english_skill_by_topic_category_level_1_free_get_all_not_auth_api/";
// 
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_2_ADD = API_SKILL_FREE + "english_skill_by_topic_category_level_2_free_add_api/";
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_2_DELETE = API_SKILL_FREE + "english_skill_by_topic_category_level_2_free_delete_api/";
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_2_GET_BY_ID = API_SKILL_FREE + "english_skill_by_topic_category_level_2_free_get_by_id_api/";
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_2_EDIT = API_SKILL_FREE + "english_skill_by_topic_category_level_2_free_edit_api/";
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_2_GET_ALL_BY_ID_CATEGORY_LEVEL_1 = API_SKILL_FREE + "english_skill_by_topic_category_level_2_free_get_all_by_id_category_level_1_api/";
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_2_GET_ALL_WITH_POST = API_SKILL_FREE + "english_skill_by_topic_category_level_2_free_get_all_with_post_api/";
export const API_ENGLISH_SKILL_FREE_BY_TOPIC_CATEGORY_LEVEL_2_CHANGE_STATUS_IS_DISPLAY = API_SKILL_FREE + "english_skill_by_topic_category_level_2_free_change_status_is_display_api/";

export const API_ENGLISH_SKILL_FREE_EXERCISE_BY_TOPIC_ADD = API_SKILL_FREE + "english_skill_exercise_by_topic_free_add_api/";
export const API_ENGLISH_SKILL_FREE_EXERCISE_BY_TOPIC_DELETE = API_SKILL_FREE + "english_skill_exercise_by_topic_free_delete_api/";
export const API_ENGLISH_SKILL_FREE_EXERCISE_BY_TOPIC_GET_BY_ID = API_SKILL_FREE + "english_skill_exercise_by_topic_free_get_by_id_api/";
export const API_ENGLISH_SKILL_FREE_EXERCISE_BY_TOPIC_EDIT = API_SKILL_FREE + "english_skill_exercise_by_topic_free_edit_api/";
export const API_ENGLISH_SKILL_FREE_EXERCISE_BY_TOPIC_GET_ALL_BY_ID_CATEGORY_LEVEL_2 = API_SKILL_FREE + "english_skill_exercise_by_topic_free_get_all_by_id_category_level_2_api/";
export const API_ENGLISH_SKILL_FREE_EXERCISE_BY_TOPIC_GET_ALL_WITH_POST = API_SKILL_FREE + "english_skill_exercise_by_topic_free_get_all_with_post_api/";
export const API_ENGLISH_SKILL_FREE_EXERCISE_BY_TOPIC_CHANGE_STATUS_IS_DISPLAY = API_SKILL_FREE + "english_skill_exercise_by_topic_free_category_level_2_change_status_is_display_api/";
// 
export const API_ENGLISH_SKILL_FREE_EXERCISE_BY_TOPIC_GET_ALL_BY_ID_CATEGORY_LEVEL_2_NOT_AUTH = API_SKILL_FREE + "english_skill_exercise_by_topic_free_get_all_by_id_category_level_2_not_auth_api/";