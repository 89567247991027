import { lazy } from "react";

// import i18n from "../i18n";
import * as myRouters from "../Utils/routerConstants";
import FrontendLayout_Skill from "../Frontend/Layout/FrontendLayout_Skill";

// const FrontendLayout = lazy(() => import("../Frontend/Layout/FrontendLayout"));
// const FrontendLayoutAccount = lazy(() =>
//   import("../Frontend/Layout/FrontendLayoutAccount")
// );

const HomePage = lazy(() => import("../Frontend/Pages/Home/HomePage"));
const WelcomePage = lazy(() => import("../Frontend/Pages/Welcome/WelcomePage"));
const EnglishSkillReflectiveResponseByTopicPage = lazy(() =>
  import(
    "../Frontend/Pages/MySkill/EnglishSkill/EnglishSkillReflectiveResponseByTopicPage"
  )
);
const EnglishSkillExerciseByTopicPage = lazy(() =>
  import(
    "../Frontend/Pages/MySkill/EnglishSkill/EnglishSkillByTopic/EnglishSkillExerciseByTopicPage"
  )
);
const InterviewSkillPage = lazy(() =>
  import("../Frontend/Pages/MySkill/InterviewSkill/InterviewSkillPage")
);
const SoftSkillPage = lazy(() =>
  import("../Frontend/Pages/MySkill/SoftSkill/SoftSkillPage")
);

// const LoginPage = lazy(() =>
//   import("../Frontend/Pages/Account/Login/LoginPage")
// );
// const ActiveAccountPage = lazy(() =>
//   import("../Frontend/Pages/Account/ActiveAccount/ActiveAccountPage")
// );
// const ResendActivationLinkPage = lazy(() =>
//   import(
//     "../Frontend/Pages/Account/ResendActivationLink/ResendActivationLinkPage"
//   )
// );
// const ForgotPasswordPage = lazy(() =>
//   import("../Frontend/Pages/Account/ForgotPassword/ForgotPasswordPage")
// );
// const ResetPasswordPage = lazy(() =>
//   import("../Frontend/Pages/Account/ResetPassword/ResetPasswordPage")
// );
// const RegisterPage = lazy(() =>
//   import("../Frontend/Pages/Account/Register/RegisterPage")
// );
// const LoginGooglePage = lazy(() =>
//   import("../Frontend/Pages/Account/Login/LoginGooglePage")
// );

const routers = [
  {
    exact: true,
    path: "/",
    name: "Trang chủ",
    component: HomePage,
    layout: FrontendLayout_Skill,
  },
  {
    path: myRouters.WELCOME_URL,
    name: "CHÚC MỘT NGÀY TỐT LÀNH!",
    component: WelcomePage,
    layout: FrontendLayout_Skill,
  },
  {
    path: myRouters.MY_SKILL_ENGLISH_RESPOND_REFLEXIVELY_BY_TOPIC_PAGE_URL,
    name: "Luyện kỹ năng tiếng Anh đối đáp phản xạ theo CHỦ ĐỀ",
    component: EnglishSkillReflectiveResponseByTopicPage,
    layout: FrontendLayout_Skill,
  },
  {
    path: "/english-skill-exercise-by-topic/:category_level2_id/:topic_slug/:topic_name",
    name: "Luyện kỹ năng tiếng Anh đối đáp phản xạ theo CHỦ ĐỀ",
    component: EnglishSkillExerciseByTopicPage,
    layout: FrontendLayout_Skill,
  },
  {
    path: myRouters.MY_SKILL_INTERVIEW_PAGE_URL,
    name: "Luyện kỹ năng Interview",
    component: InterviewSkillPage,
    layout: FrontendLayout_Skill,
  },
  {
    path: myRouters.MY_SKILL_SOFT_PAGE_URL,
    name: "Luyện kỹ năng Mềm",
    component: SoftSkillPage,
    layout: FrontendLayout_Skill,
  },
  // {
  //   path: "/login",
  //   name: i18n.t("Login"),
  //   component: LoginPage,
  //   layout: FrontendLayoutAccount,
  // },
  // {
  //   path: "/dang-nhap",
  //   name: i18n.t("Login"),
  //   component: LoginGooglePage,
  //   layout: FrontendLayoutAccount,
  // },
  // {
  //   path: "/forgot-password",
  //   name: i18n.t("Forgot password"),
  //   component: ForgotPasswordPage,
  //   layout: FrontendLayoutAccount,
  // },
  // {
  //   path: "/reset-password",
  //   name: i18n.t("Reset password"),
  //   component: ResetPasswordPage,
  //   layout: FrontendLayoutAccount,
  // },
  // {
  //   path: "/register",
  //   name: i18n.t("Register"),
  //   component: RegisterPage,
  //   layout: FrontendLayoutAccount,
  // },
  // {
  //   path: "/active-account",
  //   name: i18n.t("Active account"),
  //   component: ActiveAccountPage,
  //   layout: FrontendLayoutAccount,
  // },
  // {
  //   path: "/resend-link-active",
  //   name: i18n.t("Resend active link"),
  //   component: ResendActivationLinkPage,
  //   layout: FrontendLayoutAccount,
  // },
];

export default routers;
